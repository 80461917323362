
























































import LazyHydrate from 'vue-lazy-hydration';

import { SfButton, SfImage, SfIcon, SfLink } from '~/components';

import { ref, defineComponent, watch, computed, useContext } from '@nuxtjs/composition-api';
import StoresModal from '~/modules/countries/components/StoresModal.vue';
import SfCharacteristic from '~/components/molecules/SfCharacteristic/SfCharacteristic.vue';
import { useConfig, useMagentoConfiguration, useWebsiteCode } from '~/composables';

export default defineComponent({
  name: 'StoreSwitcher',
  components: {
    StoresModal,
    SfButton,
    SfCharacteristic,
    SfImage,
    LazyHydrate,
    SfIcon,
    SfLink
  },
  props: {
    fromFooter: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { config } = useConfig();
    const { app } = useContext();

    const {
      selectedCurrency,
      selectedLocale,
    } = useMagentoConfiguration();

    const {
      isMe,
      isAu
    } = useWebsiteCode();

    const isLangModalOpen = ref(false);

    const getShipToCountry = ref();
    const getShipToCountryCode = ref();

    watch(isLangModalOpen, () => {
      isLangModalOpen.value
        ? document.body.classList.add('no-scroll')
        : document.body.classList.remove('no-scroll');
    });

    getShipToCountry.value = app.$cookies.get('vsf-selected-country') || '';
    getShipToCountryCode.value = app.$cookies.get('vsf-selected-country-code') || '';

    const shipToCountry = computed(() => {
      if (isAu.value) return 'Austria';
      if (isMe.value) return 'UAE';

      return getShipToCountry.value || 'International';
    });

    const isShipToCountrySelected = computed(() => shipToCountry.value !== 'International');

    return {
      selectedCurrency,
      selectedLocale,
      isLangModalOpen,
      storeConfig: config,
      shipToCountry,
      getShipToCountryCode,
      isShipToCountrySelected,
      isAu,
      isMe,
    };
  },
});
